<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller font-scale="3" animation="cylon" />
        <p id="cancel-label">กรุณารอสักครู่...</p>
      </div>
    </template>
    <div class="card">
      <div class="card-body">ตั้งค่า PGPRO</div>
      <div class="p-2">
        <div class="row">
          <div class="col-md-2 ">
            <div class="game-menu-items">
              <div class="chx-switch-sm">
                <b-form-checkbox
                  switch
                  v-model="checkPgpro"
                  @change="UpdatePgpro(checkPgpro)"
                />
              </div>
              <img src="/games/egame/PG.png" alt="games-logo" />
              <div class="game-menu-items__name text-center h4">PG PRO</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>


<script>
import { BFormCheckbox } from "bootstrap-vue";

export default {
  components: {
    BFormCheckbox,
  },
  data() {
    return {
      checkPgpro: null,
      pgproStatus: [],
    };
  },
  mounted() {
    // this.Getlist();
    this.GetListPgpro();
  },
  methods: {
    async GetListPgpro() {
      await this.$http
        .get("/pgpro/list")
        .then((response) => {
          this.pgproStatus = response.data;
          console.log(response.data[0].pgpro_status);

          this.checkPgpro = response.data[0].pgpro_status;
        })
        .catch((error) => console.log(error));
    },
    UpdatePgpro(check) {
      const obj = {
        switchs: check,
      };

      this.$http
        .post("/pgpro/update", obj)
        .then((response) => {
          this.Success("บันทึกสำเร็จ");
        })
        .catch((error) => console.log(error));
    },
    Success(mes) {
      this.$swal({
        icon: "success",
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: "btn btn-success",
        },
      });
    },
  },
};
</script>