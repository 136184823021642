<template>
  <div class="card p-2">
    <div class="d-flex align-items-center">
      <h3 class="text-primary font-weight-bolder mb-0 mr-50">
        Commission
      </h3>

      <b-form-checkbox
        v-model="data.status"
        name="check-button"
        switch
        inline
      >
        {{ commission ? "เปิดใช้งาน" : "ปิดใช้งาน" }}
      </b-form-checkbox>
    </div>

    <validation-observer
      ref="commissionRules"
      class="my-2"
    >
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group>
              <label
                for="t1"
              >Calculate Type <span class="text-danger">*</span></label>
              <div class="small text-info">
                ผู้เล่นสามารถตรับได้พุกครั่งที่มียอด Commission ที่ยังไม่ได้รับ
              </div>
              <validation-provider
                #default="{ errors }"
                name="calculate type"
                rules="required"
              >
                <v-select
                  id="Calculate Type"
                  v-model="data.calculate_type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="option_cal"
                  :reduce="title => title.value"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <!-- <b-form-group>
              <label for="Period">Period <span class="text-danger">*</span></label>
              <div class="small text-info">
                Period will be keep according to the settings af period of the
                lost balance.
              </div>
              <validation-provider
                #default="{ errors }"
                name="period"
                rules="required"
              >
                <v-select
                  id="Period"
                  v-model="data.period"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="option_period"
                  :reduce="title => title.value"
                  :state="errors.length > 0 ? false : null"
                  :disabled="data.calculate_type !== 'all time'"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group> -->
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label
                for="t1"
              >Number of times players can receive Cashback per day
                <span class="text-danger">*</span></label>
              <div class="small text-info">
                (Set to O, nat specitying the nummber of times you press to
                receive Commission per day)
              </div>
              <validation-provider
                #default="{ errors }"
                name="receive cashback"
                rules="required"
              >
                <b-form-input
                  v-model="data.receive_cashback"
                  :state="errors.length > 0 ? false : null"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6" />

          <!-- <b-col md="6">
            <b-form-group>
              <label
                for="t1"
              >Change rate to Diamond per 1 Commission
                <span class="text-danger">*</span></label>

              <validation-provider
                #default="{ errors }"
                name="rate diamond"
                rules="required"
              >
                <b-form-input
                  v-model="rate_diamond"
                  :state="errors.length > 0 ? false : null"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->

          <b-col md="6">
            <b-form-group>
              <label
                for="t1"
              >Minimum bet amount to receive commission
                <span class="text-danger">*</span></label>
              <validation-provider
                #default="{ errors }"
                name="minimum receive commission"
                rules="required"
              >
                <b-form-input
                  v-model="data.minimum_bet"
                  :state="errors.length > 0 ? false : null"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group>
              <label
                for="t1"
              >Limit Withdraw <span class="text-danger">*</span></label>
              <div class="small text-info">
                <!-- - -->
              </div>
              <validation-provider
                #default="{ errors }"
                name="limit withdraw"
                rules="required"
              >
                <b-form-input
                  v-model="data.limit_withdraw"
                  :state="errors.length > 0 ? false : null"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group>
              <label
                for="t1"
              >Maximum amount of commission a user can earn per day
                <span class="text-danger">*</span></label>
              <div class="small text-info">
                (Set to O ta nat specify the maximum amount received per User
                per day)
              </div>
              <validation-provider
                #default="{ errors }"
                name="maximum commission day"
                rules="required"
              >
                <b-form-input
                  v-model="data.max_commission_day"
                  :state="errors.length > 0 ? false : null"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group>
              <label
                for="t1"
              >Maximum amount of commission a user can earn per week
                <span class="text-danger">*</span></label>
              <div class="small text-info">
                (Set to O to not specify the maximumn amount received per User
                per week.)
              </div>
              <validation-provider
                #default="{ errors }"
                name="maximum commission week"
                rules="required"
              >
                <b-form-input
                  v-model="data.max_commission_week"
                  :state="errors.length > 0 ? false : null"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group>
              <label
                for="t1"
              >Maximum amount of commission a user can earn per month
                <span class="text-danger">*</span></label>
              <div class="small text-info">
                (Set to O to not specity the maximum amount received per User
                per month.)
              </div>
              <validation-provider
                #default="{ errors }"
                name="maximum received month"
                rules="required"
              >
                <b-form-input
                  v-model="data.max_commission_month"
                  :state="errors.length > 0 ? false : null"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <h5 class="text-info mt-2">
              Percent(%) Commission From Valid Amount
            </h5>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label for="t1">Slot<span class="text-danger">*</span></label>
              <validation-provider
                #default="{ errors }"
                name="percent commission"
                rules="required"
              >
                <b-form-input
                  v-model="data.percent_slot"
                  :state="errors.length > 0 ? false : null"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label for="t1">Casino<span class="text-danger">*</span></label>
              <validation-provider
                #default="{ errors }"
                name="saba"
                rules="required"
              >
                <b-form-input
                  v-model="data.percent_casino"
                  :state="errors.length > 0 ? false : null"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label for="t1">Sport<span class="text-danger">*</span></label>
              <validation-provider
                #default="{ errors }"
                name="card"
                rules="required"
              >
                <b-form-input
                  v-model="data.percent_sport"
                  :state="errors.length > 0 ? false : null"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label for="t1">Lotto<span class="text-danger">*</span></label>
              <validation-provider
                #default="{ errors }"
                name="lotto"
                rules="required"
              >
                <b-form-input
                  v-model="data.percent_lotto"
                  :state="errors.length > 0 ? false : null"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label for="t1">Trade<span class="text-danger">*</span></label>
              <validation-provider
                #default="{ errors }"
                name="trade"
                rules="required"
              >
                <b-form-input
                  v-model="data.percent_trade"
                  :state="errors.length > 0 ? false : null"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label for="t1">Poker<span class="text-danger">*</span></label>
              <validation-provider
                #default="{ errors }"
                name="pocker"
                rules="required"
              >
                <b-form-input
                  v-model="data.percent_poker"
                  :state="errors.length > 0 ? false : null"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label for="t1">Card<span class="text-danger">*</span></label>
              <validation-provider
                #default="{ errors }"
                name="Card"
                rules="required"
              >
                <b-form-input
                  v-model="data.percent_card"
                  :state="errors.length > 0 ? false : null"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <div class="font-weight-bolder h5">
              Poster Image (PNG, JPEG, JPG 1000*1000px)
            </div>

            <div class="small">
              Minimum upload not more 2MB.
            </div>

            <div
              class="img-drag_and_drop"
              @drop="handleDrop"
              @dragover.prevent
              @dragenter.prevent
              @dragleave.prevent
            >
              <!-- Drag and drop zone -->
              <img
                v-if="imageUrl"
                :src="imageUrl"
                alt="uploaded image"
              >
              <span v-else>
                Drag & Drop or click to upload<br>
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  @change="handleFileUpload"
                >
              </span>
              <span
                v-if="errorMessage"
                class="error"
              >{{ errorMessage }}</span>
              <button
                v-if="imageUrl"
                class="delete-btn"
                @click="clearImage"
              >
                <i class="fa fa-trash" />
              </button>
            </div>
          </b-col>

          <b-col cols="12">
            <b-form-group class="mt-1">
              <label for="GameType">Game Type<span class="text-danger">*</span></label>
              <validation-provider
                #default="{ errors }"
                name="Game Type"
                rules="required"
              >
                <v-select
                  id="GameType"
                  v-model="data.game_type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="option"
                  :reduce="title => title"
                  :state="errors.length > 0 ? false : null"
                  multiple
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- {{ data.game_type }} -->
            <div
              v-for="game in data.game_type"
              :key="game.title"
            >

              <b-row v-if="game.title === 'Slot'">
                <b-col md="6">
                  <b-form-group>
                    <label for="Slot">Slot <span class="text-danger">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="Slot"
                      rules="required"
                    >
                      <b-form-input
                        v-model="data.turn_slot"
                        :state="errors.length > 0 ? false : null"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group>
                    <label for="Type">Type <span class="text-danger">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="Type"
                      rules="required"
                    >
                      <v-select
                        id="Type"
                        v-model="data.type_slot"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :options="option_gametype"
                        :reduce="title => title.value"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row v-if="game.title === 'Casino'">
                <b-col md="6">
                  <b-form-group>
                    <label for="Casino">Casino <span class="text-danger">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="Lotto"
                      rules="required"
                    >
                      <b-form-input
                        v-model="data.turn_casino"
                        :state="errors.length > 0 ? false : null"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group>
                    <label for="Type">Type <span class="text-danger">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="Type"
                      rules="required"
                    >
                      <v-select
                        id="Type"
                        v-model="data.type_casino"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :options="option_gametype"
                        :reduce="title => title.value"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row v-if="game.title === 'Sport'">
                <b-col md="6">
                  <b-form-group>
                    <label for="Sport">Sport <span class="text-danger">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="Lotto"
                      rules="required"
                    >
                      <b-form-input
                        v-model="data.turn_sport"
                        :state="errors.length > 0 ? false : null"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group>
                    <label for="Type">Type <span class="text-danger">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="Type"
                      rules="required"
                    >
                      <v-select
                        id="Type"
                        v-model="data.type_sport"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :options="option_gametype"
                        :reduce="title => title.value"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row v-if="game.title === 'Lotto'">
                <b-col md="6">
                  <b-form-group>
                    <label for="Lotto">Lotto <span class="text-danger">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="Lotto"
                      rules="required"
                    >
                      <b-form-input
                        v-model="data.turn_lotto"
                        :state="errors.length > 0 ? false : null"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group>
                    <label for="Type">Type <span class="text-danger">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="Type"
                      rules="required"
                    >
                      <v-select
                        id="Type"
                        v-model="data.type_lotto"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :options="option_gametype"
                        :reduce="title => title.value"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row v-if="game.title === 'Trade'">
                <b-col md="6">
                  <b-form-group>
                    <label for="Trade">Trade <span class="text-danger">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="Trade"
                      rules="required"
                    >
                      <b-form-input
                        v-model="data.turn_trade"
                        :state="errors.length > 0 ? false : null"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group>
                    <label for="Type">Type <span class="text-danger">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="Type"
                      rules="required"
                    >
                      <v-select
                        id="Type"
                        v-model="data.type_trade"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :options="option_gametype"
                        :reduce="title => title.value"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row v-if="game.title === 'Poker'">
                <b-col md="6">
                  <b-form-group>
                    <label for="Poker">Poker <span class="text-danger">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="Poker"
                      rules="required"
                    >
                      <b-form-input
                        v-model="data.turn_poker"
                        :state="errors.length > 0 ? false : null"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group>
                    <label for="Type">Type <span class="text-danger">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="Type"
                      rules="required"
                    >
                      <v-select
                        id="Type"
                        v-model="data.type_poker"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :options="option_gametype"
                        :reduce="title => title.value"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row v-if="game.title === 'Card'">
                <b-col md="6">
                  <b-form-group>
                    <label for="Card">Card <span class="text-danger">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="Card"
                      rules="required"
                    >
                      <b-form-input
                        v-model="data.turn_card"
                        :state="errors.length > 0 ? false : null"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group>
                    <label for="Type">Type <span class="text-danger">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="Type"
                      rules="required"
                    >
                      <v-select
                        id="Type"
                        v-model="data.type_card"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :options="option_gametype"
                        :reduce="title => title.value"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row v-if="game.title === 'All'">
                <b-col md="6">
                  <b-form-group>
                    <label for="All">All <span class="text-danger">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="All"
                      rules="required"
                    >
                      <b-form-input
                        v-model="data.turn_all"
                        :state="errors.length > 0 ? false : null"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group>
                    <label for="Type">Type <span class="text-danger">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="Type"
                      rules="required"
                    >
                      <v-select
                        id="Type"
                        v-model="data.type_all"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :options="option_gametype"
                        :reduce="title => title.value"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

            </div>

          </b-col>

          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="validationForm"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    vSelect,
  },
  data() {
    return {
      data: {
        status: false,
        calculate_type: '',
        period: '',
        receive_cashback: '',
        minimum_bet: '',
        limit_withdraw: '',
        max_commission_day: '',
        max_commission_week: '',
        max_commission_month: '',
        percent_slot: '',
        percent_casino: '',
        percent_sport: '',
        percent_lotto: '',
        percent_trade: '',
        percent_poker: '',
        percent_card: '',
        img: '',
        game_type: [],
        turn_slot: '',
        type_slot: '',
        turn_casino: '',
        type_casino: '',
        turn_sport: '',
        type_sport: '',
        turn_lotto: '',
        type_lotto: '',
        turn_trade: '',
        type_trade: '',
        turn_poker: '',
        type_poker: '',
        turn_card: '',
        type_card: '',
        turn_all: '',
        type_all: '',
      },
      required,
      imageUrl: null,
      errorMessage: null,
      maxSize: 2 * 1024 * 1024,
      option_period: [
        { title: '7 day', value: 7 },
        { title: '30 day', value: 30 },
      ],
      option_cal: [
        { title: 'daily', value: 'daily' },
        { title: 'weekly', value: 'weekly' },
        { title: 'monthly', value: 'monthly' },
        // { title: 'all time', value: 'all time' },
      ],
      option_gametype: [
        { title: 'Turnover', value: 'turnover' },
        // { title: 'Winover', value: 'winover' },
      ],
      option: [
        // { title: 'Slot', value: 'Slot' },
        // { title: 'Casino', value: 'Casino' },
        // { title: 'Sport', value: 'Sport' },
        // { title: 'Lotto', value: 'Lotto' },
        // { title: 'Trade', value: 'Trade' },
        // { title: 'Poker', value: 'Poker' },
        // { title: 'Card', value: 'Card' },
        { title: 'All', value: 'All' },
      ],
    }
  },
  async mounted() {
    this.getSetting()
  },
  methods: {
    async getSetting() {
      await this.$http
        .get(
          '/rolling/getsetting',
        )
        .then(response => {
          // console.log(response.data)
          this.show = false
          if (response.data.status === true) {
            this.data = response.data.data
            if (this.data.img) {
              this.imageUrl = this.data.img
              console.log(this.imageUrl)
            }
          }
        })
        .catch(error => console.log(error))
    },
    validationForm() {
      this.$refs.commissionRules.validate().then(success => {
        if (success) {
          // console.log(this.imageUrl)
          if (this.imageUrl) {
            this.data.img = this.imageUrl
          }
          this.$http
            .post(
              '/rolling/setting',
              this.data,
            )
          // eslint-disable-next-line no-unused-vars
            .then(response => {
              // console.log(response)
              this.Success('บันทึกข้อมูลสำเร็จ')
            })
            .catch(error => console.log(error))
        }
      })
    },
    handleFileUpload(event) {
      const file = event.target.files[0]
      this.handleFile(file)
    },
    handleDrop(event) {
      event.preventDefault()
      const file = event.dataTransfer.files[0]
      this.handleFile(file)
    },
    handleFile(file) {
      if (!file.type.match('image.*')) {
        this.errorMessage = 'Please upload an image file.'
        return
      }
      if (file.size > this.maxSize) {
        this.errorMessage = 'File size exceeds 2MB limit.'
        return
      }

      const reader = new FileReader()
      reader.onload = event => {
        this.imageUrl = event.target.result
        this.errorMessage = null
      }
      reader.readAsDataURL(file)
    },
    clearImage() {
      this.imageUrl = null
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #000">Error!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style scoped>
.img-drag_and_drop {
  position: relative;
  border: 2px dashed #ccc;
  padding: 20px;
  border-radius: 12px;
  text-align: center;
  cursor: pointer;
}

.img-drag_and_drop img {
  max-width: 100%;
  max-height: 300px;
}

.error {
  color: red;
}

.delete-btn {
  position: absolute;
  top: 10px;
  right: 20px;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  color: red;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
